<template>
  <div class="home">
    <div class="home-cover">
      <AppHeader/>
      <div class="page-content">
        <h1 class='title'>いい感じのキャッチコピー</h1>
        <h2 class='title'> ~ もうちょいなんかいい感じの ~ </h2>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AppHeader from '../components/AppHeader.vue'
import {createDefaultTitle} from '../lib/util';

export default {
  name: 'Home',
  components: {
    AppHeader
  },
  created() {
    createDefaultTitle();
  }
}
</script>

<style scoped>
 h1{
   margin: 0;
 }

 .title{
   color: #fff;
 }
 .page-content{
   margin-top: 10%;
   text-align: center;
 }
 .home-cover{
   background-color: rgba(0, 0, 0, 0.3);
   height: 100vh;
 }

 .home{
   margin: 0;
   padding: 0;
   height: 100vh;
   background-color: rgba(0 ,0 ,0 ,0.5);
   background-size: cover ;
   background-repeat: no-repeat;
   background-image: url('~@/assets/home_background.jpg') !important;
 }

 .home::before, .home::after{
    position: fixed;
    z-index: 1;
    top: -15px;
    display: block;
    visibility: hidden;
    width: 50%;
    height: 130%;
    content: '';
     background-color: rgb(155, 50, 50);
 }

 .home::before {
   left: 0;
  animation: curtain_left 3s;
 }

 .home::after{
   right: 0;
  animation: curtain_right 3s;
 }

 @keyframes curtain_left {
   0%{ visibility: visible;}
   20%{
     transform: rotate(0deg) translateX(0%);
     background-color: rgb(155, 50, 50);
   }
   80%{
     opacity: 1;
   }
   100%{
     transform: rotate(10deg) translateX(-100%);
     opacity: 0;
     visibility: hidden;
   }

 }

 @keyframes curtain_right {
   0%{ visibility: visible;}
   20%{
     transform: rotate(0deg) translateX(0%);
     background-color: rgb(155, 50, 50);
   }
   80%{
     opacity: 1;
   }
   100%{
     transform: rotate(-10deg) translateX(100%);
     opacity: 0;
     visibility: hidden;
   }

 }

 @media screen and (max-width: 640px){
   .page-content{
    margin-top: 10%;
    margin-bottom: auto;
    text-align: center;
   }
   .home{
     margin: 0;
     padding: 0;
     width: 100vw;
     background-size: cover ;
     background-image: url('~@/assets/home_back_sp.jpg') !important;
   }
 }
</style>
