<template>
  <!-- <AppHeader></AppHeader> -->
  <router-view/>
</template>

<script>
// import AppHeader from './components/AppHeader.vue'

export default{
  name: 'app',
  // components: {AppHeader}
}
</script>


<style>
@import url('http://mplus-fonts.sourceforge.jp/webfonts/basic_latin/mplus_webfonts.css');
@import url('http://mplus-fonts.sourceforge.jp/webfonts/general-j/mplus_webfonts.css');

@font-face {
    font-family: 'M+ 1c Regular';
    src: url('./assets/fonts/mplus-1c-medium-sub.eot');
    src: url('./assets/fonts/mplus-1c-medium-sub.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/mplus-1c-medium-sub.woff') format('woff'),
    url('./assets/fonts/mplus-1c-medium-sub.ttf') format('truetype');
}
body{
  margin: 0;
  padding: 0;
  font-family: 'M+ 1c medium';
  background-color:  #e6e6e6;
}

#app {
  margin: 0;
  padding: 0;
  width: 100% ;
  height: 100%;
  text-align: center;
  color: #2c3e50;
}

</style>
