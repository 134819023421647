<template>
  <header>
    <!-- <router-link to="/"> <a> 酔いどれサーカス </a> </router-link> -->
    <router-link to="/">
      <a>
        <object type="image/svg+xml" id="logo-pc" :data="require('../assets/logo.svg')"></object>
        <object type="image/svg+xml" id="logo-sp" :data="require('../assets/logo_sp.svg')"></object>
      </a>
    </router-link>

    <nav class="pc-nav">
      <ul>
        <li><router-link to="/"> <a> HOME </a> </router-link></li>
        <li><router-link to="/members"> <a> Member </a> </router-link></li>
        <li><router-link to="/faq"> <a> FAQ </a> </router-link></li>
      </ul>
    </nav>
    <nav class="sp-nav" :class="{'active': isActive}">
      <ul>
        <li><router-link to="/"> <a> HOME </a> </router-link></li>
        <li><router-link to="/members"> <a> Member </a> </router-link></li>
        <li><router-link to="/faq"> <a> FAQ </a> </router-link></li>
        <li class="close" @click="toggleMenu"><span>閉じる</span></li>
      </ul>
    </nav>
    <span ></span>
    <div id="hamburger" @click="toggleMenu">
      <span></span>
    </div>
  </header>
</template>

<script>
export default {
  data(){
    return{
      isActive: false,
    }
  },
  methods: {
    toggleMenu(){
      this.isActive = !this.isActive
    }
  }
}


</script>

<style scoped>
  header{
    width: 100%;
    /* padding: 15px 0 15px 0; */
    background-color: #fff;
    display: flex;
    align-content: center;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: 20px;
  }

  a{
    text-decoration: none;
    color: #4b4b4b;
  }
  ul{
    list-style: none;
    margin: 0;
    display: flex;
  }

  li{
    margin: 0 0 0 15px;
    font-weight: bold;
  }

  li:hover  a{
    color: rgb(155, 50, 50);
  }

  nav{
    margin:  15px 2% 15px auto;
  }

  .sp-nav{
    display: none;
  }
  #logo-pc{
    height: 50px;
  }

  #logo-sp{
    display: none;
  }

  @media screen and (max-width: 640px){
    #logo-pc{
      display: none;
    }
     #logo-sp{
      display: block;
      height: 45px;
    }

    .pc-nav{
      display: none;
    }

    .sp-nav.active{
      margin: 0;
      transform: translateY( 0 );
      opacity: 1;
    }

    .sp-nav{
      z-index: 1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: block;
      background-color: rgba(0, 0, 0, 0.8);
      transition: all.2s ease-in-out;
      opacity: 0;
      transform: translateY( -100%);
    }

    .sp-nav ul{
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .sp-nav li{
      margin: 0;
      padding: 0;
    }

    .sp-nav a{
      color: #fff;
    }

    .sp-nav li span{
      font-size: 15px;
      color: #fff;
    }

    .sp-nav li a, .sp-nav li span{
      display: block;
      padding: 20px 0;
    }

    .sp-nav .close{
      position: relative;
      padding-left: 20px;
    }

    .sp-nav .close::before{
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 16px;
      height: 1px;
      background: #fff;
      transform: rotate(45deg);
    }

    .sp-nav .close::after{
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 16px;
      height: 1px;
      background: #fff;
      transform: rotate(-45deg);
    }



    header{
      padding:  0;
    }

    #hamburger {
      position: relative;
      display: block;
      width: 30px;
      height: 25px;
      margin: auto 10px auto auto;
    }

    #hamburger span{
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: #4b4b4b;
      transform: translateY(-50%);
    }

    #hamburger::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width:  100%;
      height: 2px;
      background-color: #4b4b4b;
    }

    #hamburger::after{
      content: "";
      display: block;
      position: absolute;
      bottom:  0;
      left: 0;
      width:  100%;
      height: 2px;
      background-color: #4b4b4b;
    }
  }
</style>
