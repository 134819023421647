import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/members',
    name: 'Members',
    component: () => import(/* webpackChunkName: "members" */ '../views/Members.vue')
  },
  {
    path: '/members/:id',
    name: 'Member',
    component: () => import('../views/Member.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import( '../views/FAQ.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
